export default {
    name: "marketmover",
    created () {
        //window.clickTest= this.clickTest
        window.getRicToDetails = this.getRicToDetails;
        window.getRicToUnderlyingChart = this.getRicToUnderlyingChart;
    },
    mounted () {
        this.$nextTick(function () {
            this.statisticsLoad();
            this.tradedStockLoad();
        });
    },
    data () {
        return {};
    },
    methods: {
        statisticsLoad () {
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: this.$js.webservice + "GetMarketData",
                data: { token: "webkey", type: 'ranking' },
                success: (response) => {
                    if (response.status == 'SUCCESS') {
                        this.topIndexAppend(response.topwarrant);
                        $("#gainer").append(this.htmlAppend(response.gainer));
                        $("#loser").append(this.htmlAppend(response.loser));
                    }
                },
                error: function (XMLHttpRequest) {
                }
            });
        },
        tradedStockLoad () {
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: this.$js.webservice + "GetMarketData",
                data: { token: "webkey", type: 'toptradedstock' },
                success: (response) => {
                    console.log(response)
                    if (response.status == 'SUCCESS') {
                        this.topStockAppend(response.topList);
                    }
                },
                error: function (XMLHttpRequest) {
                }
            });
        },
        topStockAppend (data) {
            var htmlStr = "";
            //underlying_ticker + underlying_turnover + underlying_closebid + underlyingbid_pctchng + dsply_nmll + BID + exercise_price + conv_ratio + maturity
            for (var i = 0; i < data.length; i++) {
                var isRicExist = this.$js.isExistRic(data[i].ric);//判断ric是否存在
                if (this.$js.isRHB(data[i].issuer_name)) {
                    htmlStr += "<tr class='text-center font-weight-bold'>";
                } else {
                    htmlStr += "<tr class='text-center'>";
                }
                if (isRicExist) {
                    htmlStr += "<td><a href='javascript:getRicToUnderlyingChart(\"" + data[i].underlying_ticker + "\")'>" + data[i].underlying_ticker + "</a></td>";
                } else {
                    htmlStr += "<td>" + data[i].underlying_ticker + "</td>";
                }
                htmlStr += "<td>" + this.$js.formatNumber(data[i].underlying_acvol_1 / 100, 0, 1) + "</td>";
                htmlStr += "<td>" + this.$js.curreryRetrun(data[i].underlying_curr) + " " + this.$js.formatNumber(data[i].underlying_price, 0, 1) + "</td>";
                if (data[i].underlying_pctchng >= 0) {
                    htmlStr += "<td class='details-upcolor'>" + this.$js.changeRate(this.$js.formatNumber(data[i].underlying_pctchng, 1, 0)) + "</td>";
                } else {
                    htmlStr += "<td class='details-downcolor'>" + this.$js.formatNumber(data[i].underlying_pctchng, 1, 0) + "</td>";
                }
                if (isRicExist) {
                    htmlStr += "<td><a href='javascript:getRicToDetails(\"" + data[i].ric + " " + data[i].dsply_nmll + "\")'>" + data[i].dsply_nmll + "</a></td>";
                } else {
                    htmlStr += "<td>" + data[i].dsply_nmll + "</td>";
                }
                htmlStr += "<td>" + this.$js.spaceData(this.$js.formatNumber(data[i].BID, 0, 1)) + "</td>";
                htmlStr += "<td>" + this.$js.curreryRetrun(data[i].underlying_curr) + " " + this.$js.spaceData(this.$js.formatNumber(data[i].exercise_price, 0, 1)) + "</td>";
                htmlStr += "<td>" + this.$js.spaceData(this.$js.formatNumber(data[i].conv_ratio, 4, 0)) + "</td>";
                // htmlStr += "<td>"+ this.$js.formatDate(data[i].maturity) +"</td>";
                if (data[i].BID_PCTCHNG >= 0) {
                    htmlStr += "<td class='details-upcolor'>" + this.$js.changeRate(this.$js.formatNumber(data[i].BID_PCTCHNG, 1, 0)) + "</td>";
                } else {
                    htmlStr += "<td class='details-downcolor'>" + this.$js.formatNumber(data[i].BID_PCTCHNG, 1, 0) + "</td>";
                }
                htmlStr += "</tr>";
            }
            $("#topstock").append(htmlStr);
        },
        topIndexAppend (data) {
            console.log(data)
            var htmlStr = "";
            for (var i = 0; i < data.length; i++) {
                var isRicExist = this.$js.isExistRic(data[i].ric);//判断ric是否存在
                if (this.$js.isRHB(data[i].issuer_name)) {
                    htmlStr += "<tr class='text-center font-weight-bold text-nowrap'>";
                } else {
                    htmlStr += "<tr class='text-center'>";
                }
                if (isRicExist) {
                    htmlStr += "<td><a href='javascript:getRicToDetails(\"" + data[i].ric + " " + data[i].dsply_nmll + "\")'>" + data[i].dsply_nmll + "</a></td>";
                } else {
                    htmlStr += "<td>" + data[i].dsply_nmll + "</td>";
                }
                htmlStr += "<td>" + this.$js.rewriteIssueName(this.$js.spaceData(data[i].issuer_name)) + "</td>";
                htmlStr += "<td>" + data[i].type + "</td>";
                htmlStr += "<td>" + this.$js.formatNumber(data[i].ACVOL_1/100, 0, 1) + "</td>";
                htmlStr += "<td>" + this.$js.spaceData(this.$js.formatNumber(data[i].BID, 0, 1)) + "</td>";
                if (data[i].BID_PCTCHNG >= 0) {
                    htmlStr += "<td class='details-upcolor'>" + this.$js.changeRate(this.$js.formatNumber(data[i].BID_PCTCHNG, 1, 0)) + "</td>";
                } else {
                    htmlStr += "<td class='details-downcolor'>" + this.$js.formatNumber(data[i].BID_PCTCHNG, 1, 0) + "</td>";
                }
                htmlStr += "<td>" + this.$js.curreryRetrun(data[i].underlying_curr) + " " + this.$js.spaceData(this.$js.formatNumber(data[i].exercise_price, 0, 1)) + "</td>";
                htmlStr += "<td>" + this.$js.spaceData(this.$js.formatNumber(data[i].conv_ratio, 4, 1)) + "</td>";
                htmlStr += "<td>" + data[i].maturity + "</td>";
                htmlStr += "</tr>";
            }
            $("#topindex").append(htmlStr);
        },
        htmlAppend (data) {
            var htmlStr = "";
            for (var i = 0; i < data.length; i++) {
                var isRicExist = this.$js.isExistRic(data[i].ric);//判断ric是否存在
                if (this.$js.isRHB(data[i].issuer_name)) {
                    htmlStr += "<tr class='text-center font-weight-bold'>";
                } else {
                    htmlStr += "<tr class='text-center'>";
                }
                if (isRicExist) {
                    htmlStr += "<td><a href='javascript:getRicToDetails(\"" + data[i].ric + " " + data[i].dsply_nmll + "\")'>" + data[i].dsply_nmll + "</a></td>";
                } else {
                    htmlStr += "<td>" + data[i].dsply_nmll + "</td>";
                }
                htmlStr += "<td>" + this.$js.rewriteIssueName(this.$js.spaceData(data[i].issuer_name)) + "</td>";
                htmlStr += "<td>" + data[i].type + "</td>";
                htmlStr += "<td>" + this.$js.formatNumber(data[i].BID, 0, 1) + "</td>";

                if (parseInt(data[i].BID_PCTCHNG) > 0) {
                    htmlStr += "<td class='details-upcolor'>" + this.$js.changeRate(this.$js.formatNumber(data[i].BID_PCTCHNG, 1, 1)) + "</td>";
                } else {
                    htmlStr += "<td class='details-downcolor'>" + this.$js.formatNumber(data[i].BID_PCTCHNG, 1, 1) + "</td>";
                }
                htmlStr += "<td>" + this.$js.formatNumber(data[i].ACVOL_1 / 100, 0, 1) + "</td>";
                htmlStr += "<td>" + this.$js.curreryRetrun(data[i].underlying_curr) + " " + this.$js.spaceData(this.$js.formatNumber(data[i].exercise_price, 0, 1)) + "</td>";
                htmlStr += "<td>" + this.$js.spaceData(this.$js.formatNumber(data[i].conv_ratio, 4, 1)) + "</td>";
                htmlStr += "<td>" + data[i].maturity + "</td>";
                htmlStr += "</tr>";
            }
            return htmlStr;
        },
        getRicToUnderlyingChart (ric) {
            localStorage.setItem('underlyingRic', ric);
            var routes = this.$router.resolve({
                path: "/id/UnderlyingChart",
                query: { dsplay_nmll: ric }
            });
            location.href = routes.href;
        },
        getRicToDetails (ric) {
            localStorage.setItem('detailsRic', ric.split(" ")[0]);
            var routes = this.$router.resolve({
                path: "/id/WarrantDetails",
                query: { dsplay_nmll: ric.split(" ")[1] }
            });
            location.href = routes.href;
        }
    }
}